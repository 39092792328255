<template>
  <div>
    <div v-for="(item, index) in data" :key="index" class="card-item">
      <div class="row">
        <div class="left size28">
          <span>订单编号：</span>
          <span class="theme-color">{{ item.ddbm }}</span>
        </div>
      </div>
      <div class="text-row">
        诊断结果：<span>{{ item.zd }}</span>
      </div>
      <div class="text-row">
        就诊人：{{ item.jzrxm }}
      </div>
      <div class="text-row">
        医生姓名：{{ item.ysxm }}
      </div>
      <div class="border" />
      <div class="row mt24">
        <div class="left desc">
          <span class="docInfo">{{ item.createTime }}</span>
        </div>
        <div class="right">
          <div v-if="item.ddzt" class="status blue" @click="routeTo(item)">
            {{ item.ddztmc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InspectItem",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "jy"
    },
    // true:我的处方, false:我的购药
    isPrescription: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    routeTo(item) {
      this.$router.push(`/inspectPay?id=${item.id}`)
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.card-item {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 24px 32px;
  box-sizing: border-box;

  .size28 {
    font-size: 28px;
  }

  .row {
    display: flex;
    font-weight: 400;

    .left {
      display: flex;
    }

    .right {
      flex: 1;
    }

    .large-font {
      font-weight: 400;
      font-size: 28px;
      color: @dark-font-color;
    }

    .theme-color {
      color: @theme-color;
    }

    .photo {
      margin-right: 24px;
    }

    .docInfo {
      line-height: 48px;
      font-size: 24px;
    }
  }

  .desc {
    font-size: 28px;
    color: #666666;
  }

  .text-row {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
  }
  .fontSize {
    font-size: 26px !important;
    color: #333333;
    margin-top: 24px;
  }
  .border {
    border-top: 1px solid #eeeeee;
    margin-bottom: 24px;
    width: 100%;
    margin-top: 16px;
  }
}

.mt24 {
  margin-top: 24px;
}

.status {
  width: 130px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  float: right;
  font-size: 24px;
}

.grey {
  border: 1px solid #dddddd;
  color: #999;
}

.blue {
  border: 1px solid #1c9cf6;
  color: #1c9cf6;
}
</style>

<!--
 * @Date: 2023-01-31 15:59:27
 * @LastEditTime: 2023-09-26 15:16:15
 * @Description: 
 * @FilePath: \lsz-user-mp\src\views\user\order\list.vue
-->
<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <div class="tab-list">
      <van-button v-for="(item, index) in tabList" :key="'tab' + index" class="tab-btn" :class="tabActive === index ?'tab-btn-selected':''" round type="info" @click="onClickTab(item.value)">
        {{ item.text }}
      </van-button>
    </div>
    <load-more
      :data-source="dataList"
      :total="total"
      :loading="loading"
      :immediate-check="false"
      @load-more="onLoad"
    >
      <prescription-item
        v-if="tabActive == 0"
        :data="dataList"
        type="order"
        :is-prescription="false"
      />
      <inspect-item
        v-if="tabActive == 1"
        :data="dataList"
        type="order"
        :is-prescription="false"
      />
    </load-more>
  </div>
</template>
<script>
import { userModel } from "@/api/user"
import loadMore from "@/assets/js/mixin/load-more"
import { appointModel } from "@/api/appoint"
import inspectItem from "../../../components/inspectItem/inspectItem.vue"
export default {
  name: "List",
  components: { inspectItem },
  mixins: [loadMore],
  data() {
    return {
      Picker: false,
      patientIdCard: "",
      dataList: [],
      tabActive: 0,
      option1: [],
      tabList: [
        { text: "处方", value: 0 },
        { text: "检验", value: 1 }
      ]
    }
  },
  mounted() {},
  methods: {
    getList() {
      if (this.tabActive == 0) {
        let params = {
          zjhm: this.patientIdCard,
          page: this.page,
          pageSize: this.pageSize,
          prescriptionId: ""
        }
        userModel.prescriptionList(params).then((res) => {
          if (res.data && res.data.length > 0) {
            this.dataList.push(...res.data)
          }
          this.total = res.count
          this.loading = false
        })
      } else {
        let params = {
          page: this.page,
          pageSize: this.pageSize,
          zjhm: this.patientIdCard
        }
        appointModel.getJyList(params).then((res) => {
          if (res.data && res.data.length > 0) {
            this.dataList.push(...res.data)
          }
          this.total = res.count
          this.loading = false
        })
      }
    },
    onLoad() {
      this.loading = true
      this.page += 1
      this.getList()
    },

    patientConfirm(value) {
      this.loading = true
      this.patientIdCard = value.zjh || ""
      this.page = 1
      this.dataList = []
      this.getList()
    },
    onClickTab(val) {
      this.loading = true
      this.tabActive = val
      this.page = 1
      this.dataList = []
      this.getList()
    }
  }
}
</script>
<style scoped>
.tab-list{
  display: flex;
  padding: 24px 32px 0;
}
.tab-btn{
  padding: 8px 56px !important;
  border: 1px solid #3A8AE5;
  background: #fff;
  color: #3A8AE5;
  margin-right: 24px;
  height: fit-content;
}
.tab-btn-selected{
  background: #3A8AE5;
  color: #fff;
}
</style>
